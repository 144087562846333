.event-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.icons-container {
  position: absolute;
  top: 10px; /* Adjust based on your desired position */
  right: 10px; /* Adjust based on your desired position */
  display: flex;
}

.event-icon {
  margin-left: 5px; /* Space between the icons */
  color: white; /* Change color to make it more visible on images */
  cursor: pointer; /* Makes it clear they're interactive */
}

.MuiCard-root .MuiCardActionArea-root {
  position: relative; /* Makes it the reference for absolute positioning */
}
.event-container .MuiCard-root {
  margin: 20px;
  width: 300px; /* Adjust this value as needed */
}

.event-container .MuiCard-root .MuiButton-root {
  margin: 10px;
}

.event-container .MuiCard-root .MuiSvgIcon-root {
  margin: 10px;
}
