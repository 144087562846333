/* Default styling */
.ablog-post {
    flex: 1;
    flex-basis: 100%;
    max-width: 1440px;
    margin: 10px auto;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content vertically */
    width: 100%;
    color: #000;
}

.ablog-post img {
    width: 60% !important;
    height: auto !important;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
    display: block;
    max-height: 1000px;
    margin-right: 70px;
}

.ablog-post section {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content vertically */
    color: #000;
}

.ablog-post h2 {
    margin-top: 20px;
    font-size: 1.8rem !important;
    color: #000 !important;
    font-weight: 600;
    margin-left: 250px;
}

.ablog-post h1 {
    font-size: 2.2em;
    line-height: 1.5;
    color: #000;
    margin-bottom: 20px;
    margin-left: 100px;
}

.ablog-post p {
    font-size: 1.1em;
    line-height: 1.5;
    color: #000;
    margin-bottom: 20px;
    margin-left: 250px;
}

/* Specific styles for .ablog-post with styleId 2 */
.ablog-post.style-2 {
    /* Your specific styles for styleId 2 here */
    /* For example, different colors and margins: */
    background-color: white;
    margin: 0px auto;
    color: #333;
    /* Stretch the main image to fit the width */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Add any other styles you want for styleId 2 */
    position: relative; /* Add relative positioning to the container */
}

/* Stretch the main image inside .ablog-post.style-2 */
.ablog-post.style-2 img {
    width: 100% !important; /* Stretch the image to 100% of the container's width */
    height: auto; /* Allow the image to maintain its aspect ratio */
}

.ablog-post.style-2 h1 {
    font-size: 2rem;
    position: absolute; /* Position the h1 absolutely relative to its container */
    top: 630px; /* Place it vertically at the middle of the container */
    left: 50%; /* Place it horizontally at the middle of the container */
    transform: translate(-50%, -50%); /* Center the h1 both horizontally and vertically */
    margin: 20px; /* Add some margin as needed */
    color: #fff; /* Adjust the text color for better visibility */
    background-color: rgba(0, 0, 0, 0.7); /* Add a background color for better contrast */
    padding: 10px; /* Add padding for better text readability */
}


.ablog-post.style-2 h3 {
    font-size: 1.2rem;
    position: absolute; /* Position the h1 absolutely relative to its container */
    top: 685px; /* Place it vertically at the middle of the container */
    left: 50%; /* Place it horizontally at the middle of the container */
    transform: translate(-50%, -50%); /* Center the h1 both horizontally and vertically */
    margin: 20px; /* Add some margin as needed */
    color: #fff; /* Adjust the text color for better visibility */
    background-color: rgba(0, 0, 0, 0.7); /* Add a background color for better contrast */
    padding: 10px; /* Add padding for better text readability */
}


/* Styling for styleId 3 */
.ablog-post.style-3 {
    /* Add your specific styles for styleId 3 here */
    display: flex !important; /* Use flex layout to arrange elements side by side */
    align-items: center; /* Center elements vertically */
    background-color: #f0f0f0; /* Example background color */
    color: #333; /* Example text color */
    /* Add any other styles you want for styleId 3 */
}

/* Style the heading image on the left */
.ablog-post.style-3 .style-3-heading-image {
    width: auto;
    height: 800px !important; /* Adjust the height as needed */
    margin-right: 80px; /* Add some spacing between the image and text */
}

/* Add additional styles as needed for styleId 3 */
.ablog-post.style-3 h1 {
    font-size: 2rem;
    position: absolute; /* Position the h1 absolutely relative to its container */
    top: 820px; /* Place it vertically at the middle of the container */
    left: 52%; /* Place it horizontally at the middle of the container */
    transform: translate(-50%, -50%); /* Center the h1 both horizontally and vertically */
    margin: 20px; /* Add some margin as needed */
    color: #fff; /* Adjust the text color for better visibility */
    background-color: rgba(0, 0, 0, 0.7); /* Add a background color for better contrast */
    padding: 10px; /* Add padding for better text readability */
}

/* Style the <p> element on the right */
.ablog-post.style-3 p {
    font-size: 1.1em;
    /* Adjust the position to avoid overlap with the carousel on small screens */
    position: relative; /* Change to relative positioning for small screens */
    top: auto; /* Reset top position */
    margin-top: 20px; /* Add margin to separate the <p> from the image */
    /* Add other styles for paragraphs in styleId 3 */
}

/* If you want to target specific elements within styleId 3, use appropriate selectors */
.ablog-post.style-3 .custom-element {
    /* Add styles for custom elements within styleId 3 */
}

/* Styles for the horizontal carousel */
.acarousel-section {
    overflow: hidden;
    width: 100%;
    position: relative;
    margin-left: 50px;
}

.acarousel-content {
    display: flex; /* Display slides horizontally */
    transition: transform 0.5s; /* Add a smooth transition effect */
    width: 100%; /* Adjust the width to accommodate all images (e.g., 300% for three images) */
}

.carousel-image {
    flex: 0 0 33.33%; /* Divide the container width equally for three images */
    width: 900px !important; /* Set a specific width for the images */
    height: 900px !important; /* Set a specific height for the images */
    object-fit: cover; /* Ensure the image covers the specified dimensions */
}

/* Optional: Add navigation buttons for the horizontal carousel */
.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    z-index: 1;
}

.carousel-button.prev {
    left: 0;
}

.carousel-button.next {
    right: 0;
}

/* Media query for small screens */
@media (max-width: 768px) {
    .ablog-post.style-3 {
        flex-direction: column; /* Stack elements vertically on small screens */
    }

    .ablog-post.style-3 .style-3-heading-image {
        margin-right: 0; /* Remove margin for the image */
    }

    .ablog-post.style-3 h1 {
        position: static; /* Remove absolute positioning for the h1 on small screens */
        text-align: center; /* Center-align the h1 text on small screens */
    }

    .ablog-post.style-3 p {
        position: static; /* Remove absolute positioning for the <p> on small screens */
        text-align: left; /* Left-align the <p> text on small screens */
        margin-top: 10px; /* Add margin to separate the <p> from the image on small screens */
    }

    /* Media query to handle the specific screen size (764px x 925px) */
    @media (max-width: 764px) and (max-height: 925px) {
        .ablog-post.style-3 {
            flex-direction: column; /* Stack elements vertically at this specific screen size */
        }

        .ablog-post.style-3 p {
            margin-left: 20px; /* Add margin to separate the <p> from the image */
        }
    }
}



/* CSS for displaying logos horizontally with uniform size */
.partners-section {
    text-align: center; /* Optional: Center-align the content within the section */
}

.apartner-logos {
    display: flex;
    justify-content: center; /* Optional: Center-align the logos horizontally */
    align-items: center; /* Optional: Center-align the logos vertically */
    width: 200px !important; /* Set a fixed width for the logos */
    height: 200px !important; /* Set a fixed height for the logos */
}

.apartner-logo {
    margin: 10px !important; /* No margin for the images */
    padding: 0 !important; /* Optional: Remove any default padding on the images */

}



/* Default styling */

/* Add these styles for mobile screens */
@media (max-width: 768px) {
    .ablog-post img {
        margin-right: 0; /* Remove right margin for the image */
    }

    .ablog-post h1 {
        margin-left: 0; /* Remove left margin for h1 */
    }

    .ablog-post p {
        margin-left: 0; /* Remove left margin for p */
    }

    .ablog-post h2 {
        margin-left: 0; /* Remove left margin for h2 */
    }

    /* Add styles to center align h1 and h2 on mobile */
    .ablog-post h1,
    .ablog-post h2 {
        text-align: center;
    }
}
