.ablog {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background-image: url("../../assets/images/landing-bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    margin-top: 2rem;
    margin-bottom: 3rem;
    justify-content: center;
  }
  .ablog-section h2,
  .ablog-intro,
  .ablog-grid {
    font-family: var(--garamond);
  }
  
  .ablog-section h2,
  .ablog-grid h3 {
    color: var(--black);
    
  }
  
  .ablog-section h2 {
    font-size: 1rem;
  }
  .ablog-intro {
    color: var(--black);
    font-size: 1.3rem;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 3rem;
  }
  
  .ablog-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 4rem;
    width: 90%;
    margin: 0 auto;
    max-width: 1440px;
  }
  .ablog-grid-item {
    width: 100%;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: left;
  }
  
  .ablog-grid-item img {
    max-width: 100%;
    height: 10rem;
    object-fit: cover;
  }
  
  .ablog-grid-item h3 {
    transition: all ease-in-out 0.3s;
  }
  .ablog-grid-item .credentials {
    color: var(--whitish-gray);
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
  
  .ablog-grid-item .intro {
    color: var(--black);
  }
  
  .ablog-grid h3 {
    text-transform: capitalize;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 1.3rem !important;
  }
  
  .ablog .ablog-pagination {
    position: initial;
    display: flex;
    flex-direction: row;
    margin-top: 4rem;
    margin-bottom: 3rem;
  }
  
  
  .ablog-grid-item-wrapper {
    display: flex;
    align-items: start; /* To align items to the top */
  }
  
  .ablog-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Gap between the buttons */
    margin-right: 20px; /* Spacing between the buttons and the ablog content */
  }
  
  .banner-heading {
    display: flex;
    background-color: white;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    font-family: var(--garamond);
  }
  
  .text-container {
    flex: 1;
    text-align: center;
    margin: 0 20px;
    display: flex;
    flex-direction: column; /* Stack child elements vertically */
    align-items: center; /* Center align child elements horizontally */
  }
  
  .sdg-images {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 60%;
  }
  
  .banner-heading-img {
    max-width: 20%;
  }
  
  .signature-font {
    font-size: 18px;
    font-weight: bold;
  }
  
  h2 {
    font-size: 18px;
    margin-top: 10px;
  }
  
  /* Responsive Styles for Mobile */
  @media only screen and (max-width: 768px) {
    .banner-heading {
      flex-direction: column; /* Stack elements vertically on mobile */
      text-align: center; /* Center align text on mobile */
      padding: 10px;
    }
  
    .text-container {
      margin: 20px 0; /* Adjust margin for spacing on mobile */
    }
  
    .banner-heading-img:nth-child(1),
    .banner-heading-img:nth-child(2) {
      max-width: 50%; /* Make both images take up half of the width each on mobile */
      order: 1; /* Keep the order to place the images side by side */
    }
  
    .banner-heading-img:nth-child(3),
    .banner-heading-img:nth-child(4) {
      max-width: 50%; /* Make both SDG images take up half of the width each on mobile */
      order: 2; /* Keep the order to place the SDG images side by side under images */
    }
  
    .sdg-images {
      max-width: 40%; /* Expand SDG images to full width on mobile */
      display: flex;
      
      justify-content: center;
    }
  
    .sdg-images .banner-heading-img {
      max-width: 40%; /* Adjust the width of individual SDG images on mobile */
      margin: 5px; /* Add spacing between SDG images on mobile */
    }
  
    .banner-heading-img {
      max-width: 100%; /* Expand all images to full width on mobile */
    }
  }
  
  /***************  RESPONSIVE ***************/
  
  @media (hover: hover) {
    .ablog-grid-item h3:hover {
      color: var(--pizza-red);
      transition: all ease-in-out 0.3s;
    }
  }
  
  @media screen and (min-width: 700px) {
    .ablog-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media screen and (min-width: 1110px) {
    .ablog-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  