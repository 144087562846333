.blog-post {
  display: flex;
  font-family: 'Times New Roman', Times, serif; /* or any other serif font */
  color: #333;
  line-height: 1.6;
  max-width: 1200px; /* max width for content */
  margin: 0 auto; /* center the content */
  padding: 2rem;
  padding-bottom: 300px; /* Adjust the value based on your footer's height */

}

.blog-container {
  display: block; /* remove flex to stack elements */
  max-width: 100%;
  padding: 5rem 0; /* more vertical padding */
}

.blog-button-left {
  margin: 0.5rem 0;
  padding: 1rem 2rem;
  border: none;
  background-color: #000;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}


/* Navigation styling */
.blog-buttons {
  /* Previously .blog-button-container */
  position: sticky; /* Make it stick to the top */
  margin-top: 30px;  
  background-color: white;
  padding: 1rem;
  border-bottom: 1px solid #ccc;
  z-index: 100; /* Make sure it stacks above other content */
}

.blog-button-left:hover {
  background-color: #444;
}

/* Active button styling */
.blog-button.active {
  background-color: #555;
  color: white;
}

/* Ensure that buttons have a focus style for keyboard navigation */
.blog-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.5);
}

.blog-content {
  text-align: left;
}

.blog-content-1, .blog-content > section {
  margin-top: 2rem;
  padding-right: 2rem; /* instead of margin-left for the content */
}

.blog-post img {
  width: 100%;
  height: auto; /* maintain aspect ratio */
  margin-top: 2rem;
}

/* Additional styles for h1, h2, and p elements */
.blog-post h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.blog-post h2 {
  font-size: 2rem;
  color: #555;
}

.blog-post p {
  font-size: 1rem;
  color: #666;
  text-align: justify;
}


.sidebar {
  position: fixed; /* Fixed sidebar (stay in place on scroll) */
  top: 380px; /* Distance from the top of the view */
  margin-left: 150px;
  left: 0; /* Align to the left side of the view */
  width: 200px; /* Set the width of the sidebar */
  background-color: #f1f1f1; /* Sidebar color */
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px;
  z-index: 10; /* Ensures that the sidebar stacks above other content */
}

.content {
  margin-left: 200px; /* Same as the width of the sidebar, to prevent overlap */
  padding: 1px 16px;
  height: auto; /* Adjust if needed */
  flex-grow: 1; /* Allows content to fill space to the right of the sidebar */
}

/* Style the sidebar buttons */
.sidebar button {
  display: flex;
  align-items: center;
  color: black;
  padding: 10px 20px;
  text-decoration: none;
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  text-align: left;
  width: 100%;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

/* Add the icon styles */
.sidebar button .icon {
  font-size: 20px; /* Adjust icon size if needed */
  margin-right: 10px;
}

/* Hover effect */
.sidebar button:hover {
  background-color: #e6e6e6; /* Light grey hover effect */
  cursor: pointer;
}

/* Active button style to mimic the highlighted effect in the design */
.sidebar button.active {
  background-color: #e6e6e6;
  font-weight: bold; /* Optional: if you want to mimic the bold effect on the active button */
}

/* On hover */
.sidebar button:hover {
  background-color: #555; /* Dark-grey hover effect */
  color: white;
}


.hamburger-menu {
  display: none; /* Hidden by default, will be displayed in a responsive context */
  cursor: pointer;
}

.hamburger-icon {
  font-size: 24px;
}

/* Show the hamburger icon in smaller screens and hide the sidebar by default */
@media screen and (max-width: 700px) {
  .hamburger-menu {
    display: block; /* Show hamburger icon */
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
  }

  /* Initially hide the sidebar when on small screens */
  .sidebar {
    position: fixed;
    width: 100%;
    height: 100vh; /* Full screen height */
    top: 0;
    left: -100%; /* Hide sidebar off-screen */
    overflow-y: auto; /* Enable scroll on small devices */
    transition: all 0.3s; /* Animate the sidebar */
    padding-top: 60px; /* Make space for the close button */
    background-color: #f1f1f1; /* Sidebar color */
    z-index: 999; /* Make sure sidebar is on top */
  }

  /* When sidebar is open, slide it into view */
  .sidebar.open {
    left: 0;
  }
  .blog-post h1 {
    font-size: 2rem; /* Adjust the size as necessary */
  }
}




/* Responsive layout - when the screen is less than 700px wide, 
   make the sidebar into a topbar */
   @media screen and (max-width: 700px) {
    .sidebar {
      position: relative; /* sidebar becomes part of the flow on smaller screens */
      width: 100%; /* Full width */
      top: 0; /* Reset top position */
    }
    .content {
      padding: 1px 0; /* Adjust the padding here as necessary */

      margin-left: 0; /* Content can now use the full width of the screen */
    }

    .blog-content > section {
      /* Other styles */
      padding-right: 0; /* Set padding to 0 */
      padding-left: 0; /* Set padding to 0 */
    }
    
  }

/* Responsive layout - when the screen is less than 400px wide, 
   make the navigation buttons stack on top of each other */
@media screen and (max-width: 400px) {
  .sidebar button {
    text-align: center;
    float: none;
  }
}
