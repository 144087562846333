.form {
  display: flex;
  flex-direction: column;
  background-color: var(--light-black);
  background-image: none;
  padding-top: 2rem;
  padding-bottom: 10rem;
}

.form-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  height: 460px;
  padding: 2rem;
}
.form-success p {
  color: var(--green);
  font-family: var(--poppins-font);
  font-size: 1.5rem;

  text-align: center;
}
.form-success section {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.form-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  height: 460px;
  padding: 2rem;
}
.form-loader p {
  font-family: var(--poppins-font);
  color: var(--white);
}
.form-loader img {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
}
.form form {
  height: max-content;
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-left: 2rem;
  margin-bottom: 2rem;
}
.form form textarea {
  resize: none;
}
.form form .webflow-style-input {
  margin: 0;
}
.form-us-img {
  background-image: url("../../assets/images/img-one.jpeg");
  background-position: center;
  background-size: cover;
  height: 15rem;
  width: 100%;
  border-radius: 10px;
}

/* form Content */
.form-us-content {
  background-color: var(--yellow);
  padding-bottom: 10rem;
}

.form-us-content-txt {
  padding: 2rem;
  text-align: left;
  color: var(--white);
}
.form-us-content-txt h2 {
  font-size: 4rem;
  letter-spacing: 1px;
}
.form-us-content-txt h3 {
  line-height: 2rem;
}

.fullname-error-cpage,
.input-validation-error,
.email-error-cpage,
.textarea-error-cpage {
  font-family: var(--poppins-font);
  color: var(--pizza-red);
}

.button-msg-cpage {
  font-family: var(--poppins-font);
  color: var(--white);
  padding-top: 1rem;
}

.form .active-button-style,
.form .active-button-style:hover {
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.form .active-button-style:hover {
  border-color: var(--white);
  background-color: transparent;
}

/***************  RESPONSIVE ***************/
@media screen and (min-width: 700px) {
  .form form {
    width: 60%;
  }
}
@media screen and (min-width: 1025px) {
  .form-us-img {
    position: absolute;
    width: 30%;
    height: 30%;
    max-width: 100%;
    max-height: 100%;
    transform: translate(85%, 50%);
    top: 30%;
    right: 35%;
    border: 2px solid var(--yellow);
  }
  .form-us-content-txt {
    width: 30%;
  }
}
