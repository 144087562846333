.contact-section-comp {
  width: 90%;
  margin: 0 auto;
}

.about-us {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--white);
  font-family: var(--poppins-font);
  width: 100%;
}
.about-us h2 {
  font-size: 3rem;
}
.about-us .sub-title {
  font-size: 1.5rem;
  text-transform: uppercase;
}

.about-us p {
  color: var(--light-gray);
  font-size: 1.2rem;
  line-height: 2rem;
}
.section-one-content h3,
.section-two-content h3,
.section-five h3,
.section-three h3,
.section-four h3 {
  font-size: 2rem;
}

.section-one,
.section-two,
.section-three {
  display: flex;
  flex-direction: column;
}
.section-four,
.section-five {
  display: grid;
  grid-template-columns: 1fr;
}
.sub-title {
  color: var(--pizza-red);
  font-family: var(--nothing-font);
  letter-spacing: 1px;
}

.aboutus-img-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.aboutus-img-grid img {
  width: 100%;
  min-height: 100%;
  height: 16rem;
  max-height: 100%;
  object-fit: cover;
}

.section-one,
.section-two {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.section-one-content {
  position: relative;
  padding: 4rem;
  z-index: 999;
  background-image: url("../../assets/images/about-us/section-one.jpeg");
}
.section-one-content h2,
.section-one-content h3,
.section-one-content p {
  position: relative;
  z-index: 999;
}

.section-one-glass {
  position: absolute;
  z-index: 1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.829);
  backdrop-filter: blur(3px);
}
.section-one::after,
.section-two::after {
  content: "";
  background-color: var(--pizza-red);
  opacity: 0.3;
  width: 100%;
  height: 1px;
  bottom: 0;
  z-index: 2;
}

.section-two-content {
  margin-left: 100px;
  position: relative;
  z-index: 1;
  width: 90%;
  text-align: left;
  margin: 0 auto;
  flex: 1; /* This ensures the content takes up all the available space */
  margin-left: 600px; /* This pushes the content to the right */
}
.dark-glass {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.411);
  backdrop-filter: blur(1px);
}

/* Section 2 */
.section-two {
  display: flex; /* Makes child elements sit next to each other */
  align-items: center; /* Vertically centers child elements */
  background-image: url("../../assets/images/about-us/section-two.jpeg");
}

.black-glass {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.829);
  backdrop-filter: blur(3px);
}

.section-three-content {
  margin: 0 auto;
}

.section-three-img-grid {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  justify-content: space-evenly;
  padding: 2rem;
}
.section-three-img-grid img {
  min-width: 10rem;
  width: 100%;
  min-height: 100%;
  height: 20rem;
  max-height: 100%;
  border-radius: 15px;
}

.img-glass {
  width: 100%;
  height: 20rem;
  bottom: 0;
  background-image: url("../../assets/images/about-us/section-three.jpeg");
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  opacity: 0.7;
}

.section-four img,
.section-five img {
  width: 100%;
  min-height: 100%;
  height: 20rem;
  max-height: 100%;
  opacity: 0.8;
  object-fit: cover;
}
.section-four-content,
.section-five-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.section-two-content,
.section-three-content,
.section-four-content,
.section-five-content {
  padding: 1rem;
}

/***************  RESPONSIVE ***************/

@media screen and (min-width: 700px) {
  .section-two-content,
  .section-three-content,
  .section-four-content,
  .section-five-content {
    padding: 2rem;
  }
  .aboutus-img-grid {
    flex-direction: row;
    justify-content: center;
  }
}

@media screen and (min-width: 1025px) {
  .section-two-content,
  .section-three-content {
    width: 70%;
  }
  .section-three-img-grid {
    flex-direction: row;
  }
  .section-four,
  .section-five {
    grid-template-columns: repeat(2, 1fr);
  }
}
/***************  RESPONSIVE ***************/
@media screen and (min-width: 1110px) {
  .section-two-content,
  .section-three-content {
    width: 50%;
  }
}


.about-img {
  width: 350px;
  position: absolute;
  left: -50px;  /* Moves the image 20 pixels to the left */
  height: auto;
  flex-shrink: 0;
  max-width: 100%;
  margin-top: 600px;
  margin-left: 90px;
  z-index: 10;  /* This ensures the image overlays on top of other content */

}

/* New CSS - Media Query */
@media screen and (max-width: 768px) {
  .about-img {
    display: none; /* This will hide the image on screens smaller than 768px */
  }
}