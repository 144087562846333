.ablog-post {
  width: 100%;
  max-width: 3000px;
  display: flex;
  flex-direction: row; /* Change this to row */
  align-items: flex-start; /* Align items to the start */
  text-align: center;
}

.ablog-container {
  flex-wrap: wrap; /* Allow content to wrap to the next row if needed */
  display: flex;
  flex-direction: row; /* Stack buttons and content horizontally */
  align-items: flex-start; /* Align buttons to the top */
  flex: 1; /* Take up available space */
  max-width: 100%; /* Adjust the max-width for the container */
}



.ablog-button-left {
  margin: 1rem; /* Add margin to space out the buttons */
  padding: 1rem 1rem;
  text-decoration: none;
  background-color: #333;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s;
  min-width: 150px; /* Add this line. Adjust the value as needed */
  text-align: center; /* Add this line to center the text */
}


.ablog-button-left:hover {
  background-color: #555;
}



.ablog-content {
  display: flex;
  flex-direction: row;
}

.mblog-button-container {
  display: flex;
  flex-direction: column;
  margin-right: 1200px;
  position: absolute; /* Add this to position your element */
  top: 900px; /* Move up by 100px */
}


.ablog-content-1 {
  min-height: 500px; /* Adjust the minimum height as needed */
  overflow: auto;
}

.ablog-content > section {
  min-height: 500px;
  margin-right: 10px;
  overflow: auto;
  text-align: left; /* Add this property to left-align the content */
  padding: 0; /* Remove padding to the left-align the content */
}



.ablog-content-1 > section {
  min-height: 500px;
  overflow: auto;
  text-align: left; /* Add this property to left-align the content */
  padding: 0; /* Remove padding to the left-align the content */
  margin-left: 600px !important;
}


.ablog-post img {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  align-items: center;
  margin-left: 80px !important;
}

.ablog-post section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 1400px;
  color: var(--black);
  text-align: left;
}

/* Additional styles for h1 and p elements */
.ablog-post section h1,
.ablog-content > section h1,
.ablog-content-1 > section h1 {
  margin-left: 10px;
  font-size: 1.9rem;
  font-family: var(--garammond);
}

.ablog-post section p,
.ablog-content > section p,
.ablog-content-1 > section p {
  white-space: pre-line;
  text-align: justify;
  font-size: 1.2rem;
}
.ablog-post section h2 {
  margin-right: 200px;
  text-align: left;
  font-size: 4rem;
  font-family: var(--garammond);
}

.ablog-post section h1 {
  text-align: left;
  font-size: 1.9rem;
  font-family: var(--garammond);
}

.ablog-post section p {
  white-space: pre-line;
  text-align: justify; /* Add this property to make the paragraph justified */
  font-size: 1.2rem;
  margin-right: 200px;
}
