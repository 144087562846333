/* Container for all the posts */
.postsContainer {
  display: flex;
  flex-direction: column;
  height: 630px;
  width: 90%;
  overflow: hidden;  /* This will hide both vertical and horizontal scrollbars */
  overflow-x: visible;  /* change scroll to visible */
  overflow-y: hidden;
  white-space: nowrap;
  margin-left: 110px;
}

/* Each row of posts */
.postsRow {
  display: flex;
  flex-wrap: nowrap;
  width: max-content;
}

/* Individual post card styling */
.postCard {
  position: relative;
  width: 200px;
  height: 210px;
  overflow: hidden;
  margin: 15px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
  transition: transform 0.2s;
  padding-bottom: 4%; /* Added padding to the bottom to allow more room for text */
}

/* Hover effect for post card */
.postCard:hover {
  transform: scale(1.05);
}

/* Background overlay for text */
.postCard::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Making it take the full height, as we've added padding to postCard */
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7)); 
  z-index: 0;
}

/* Image inside the post card */
.postCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s;
}

/* Hover effect for post image */
.postCard:hover img {
  opacity: 0.7;
}

/* Date display on the post card */
.postCard p {
  position: absolute;
  bottom: 37px; /* Adjust positioning */
  left: 10px;
  width: calc(100% - 20px);
  white-space: normal;
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  z-index: 0;
}

/* Title display on the post card */
.postCard h4 {
  position: absolute;
  bottom: 0px;
  left: 10px;
  width: calc(100% - 20px);
  white-space: normal; /* Ensure text wraps to the next line */
  color: white;
  font-weight: normal;
  font-size: 16px;
  z-index: 1;
}


/* Category display on the post card */
.postCard span {
  position: absolute;
  top: 5%; /* centering the span vertically */
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  transform: translateY(-50%); /* ensures the vertical centering is accurate */
  text-transform: uppercase;
  font-weight: bold;
  z-index: 2; /* ensures it's above the other content */
}

.blogContainer .sidebar {
  /* ... existing styles ... */
  z-index: 3;  /* ensure the sidebar is on top */
}

.blogContainer .sidebar,
.blogContainer .collapsed-content,
.blogContainer .expanded-content {
    margin: 0;
    padding: 0;
}


/* By default (for larger screens), your CSS remains unchanged */
/* ... */

/* Responsive styling for smaller screens */
@media (max-width: 768px) { /* Change 768px to whatever breakpoint you desire for mobile styling */
  .postsContainer {
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;
    white-space: normal;
    padding-bottom: 15px; /* Some bottom padding for breathing room */
  }

  .postsRow {
    flex-direction: column; /* Stack cards vertically */
    align-items: center;
    width: 100%;
  }

  .postCard {
    margin: 15px auto; /* Center the cards horizontally */
  }
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  /* General Posts and Cards Styles for Mobile */
  .postsContainer {
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;
    white-space: normal;
    top: 300px;
    padding-bottom: 15px; /* Some bottom padding for breathing room */
    margin-left: 0; /* Reset the margin */
  }

  .postsRow {
    flex-direction: column; /* Stack cards vertically */
    align-items: center;
    width: 100%;
  }

  .postCard {
    margin: 15px auto; /* Center the cards horizontally */
  }



  /* If you have links, list items or any other items inside your sidebar, style them here */
  .blogContainer .sidebar a, 
  .blogContainer .sidebar li {
    margin: 0 10px; /* Some margin for space between items */
    text-decoration: none;
    color: #333; /* Adjust to your preferred color */
  }

  /* If you want a burger menu or any other icon, style them here */
  .blogContainer .sidebar .menu-icon {
    display: block; /* Show menu icon on mobile */
  }
}
