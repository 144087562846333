@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;600;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&family=Nothing+You+Could+Do&display=swap");
@font-face {
  font-family: moonshine;
  src: url(./fonts/britanny/BrittanySignature.ttf);
}

@font-face {
  font-family: Garamond;
  src: url(./fonts/EB_Garamond/EBGaramond-VariableFont_wght.ttf);
}
:root {
  --poppins-font: "Poppins", sans-serif;
  --josefin-font: "Sansita Swashed", cursive;
  --nothing-font: "Nothing You Could Do", cursive;
  --garamond:"Garamond";
  --white: #ffffff;
  --light-gray: #919191;
  --whitish-gray: #bfbfbf;
  --light-black: #121618;
  --lightish-black: #171b1d;
  --pizza-yellow: #ffe600;
  --yellow: #fac564;
  --green: #1d9f55;
  --pizza-red: #ff6240;
  --pizza-red-light: #f87d62 0;
  --transition: all ease-in-out 0.3s;
  --red: #ff0000;
  --modal-glass-bg: rgba(255, 255, 255, 0.15);
  --modal-box-shadow: -2px 10px 24px -11px #0000009e;
  --transparent-button: #ffffffa2;
  --black:#000000;
}

/* Reset */
html {
  font-size: 100%;
  background-color: var(--light-black);
}

* {
  margin: 0;
  padding: 0;
}

/* Style the anchor element within the custom-link class */
.custom-link a {
  text-decoration: none !important; /* Remove underline */
  color: white ; /* Inherit the text color from the parent element */
  /* Add any other styles you want for the link here */
}



body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url("../assets/images/landing-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  /* overflow: hidden; */
}
body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}

img {
  display: block;
}

/* Flex */
.flex-container {
  display: flex;
}

/* Flex Row */
.flex-row {
  flex-direction: row;
}

/* Flex Column */
.flex-column {
  flex-direction: column;
}
/* Text Center */
.txt-center {
  text-align: left;
  
}

/* White */
.txt-white {
  color: white;
}

/* Poppins Font */
.pop-font {
  font-family: var(--garamond);
}
.active-button-style {
  cursor: pointer;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  padding: 0.5rem 1.2rem;
  opacity: 0.8;
  background-color: var(--pizza-red);
  border-color: var(--pizza-red);
  letter-spacing: 1px;
  align-items: center;
  width: max-content;
  color: var(--white);
  font-size: 1rem;
  font-weight: 400;
  font-family: var(--poppins-font);
}
.passive-button-style {
  cursor: pointer;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  padding: 0.5rem 1.2rem;
  opacity: 0.8;
  background-color: transparent;
  border-color: var(--white);
  color: var(--white);
  letter-spacing: 1px;
  align-items: center;
  width: max-content;
  font-size: 1rem;
  font-family: var(--poppins-font);
}


/* Header */

header {
  height: 3rem;
  top: 0;
  background-color: var(--light-black);
  position: sticky;
  position: -webkit-sticky; /* Safari */
  z-index: 999;
  padding: 2rem;
}
.main-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


/* Style the sub-menu */
.sub-menu {
  display: none; /* Hide the sub-menu by default */
  position: absolute;
  background-color: #000000;
  z-index: 1; /* Ensure it appears above other content */
}

/* Style the list items in the sub-menu */
.sub-menu li {
  padding: 10px;
  text-align: center;
}

/* Show the sub-menu when hovering over the parent list item */
li:hover .sub-menu {
  display: block;
}

.disabled {
  pointer-events: none; /* Disable pointer events, making it unclickable */
  opacity: 0.5; /* Reduce opacity to visually indicate it's disabled */
  /* Add any other styles to indicate it's disabled */
}



/* Logo */
.logo-styling {
  align-items: center;
  gap: 1rem;
  width: 5rem;
  height: 3rem;
}
.logo {
  width: 10rem;
  margin-top: 10px;
  height: auto;
  max-width: 150px;
  transition: all ease-in-out 0.2s;
}




/* NAVIGATION MENU */
.navigation-menu {
  display: none;
  gap: 2rem;
  font-weight: 400;
  font-size: 1.1rem;
  align-items: center;
  
}

.navigation-menu a {
  transition: all ease-in-out 0.2s;
}
/* Login & Cart */
.login-and-cart {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.cart-btn {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-weight: 600;
}
.cart-btn img {
  width: 1.5rem;
  height: 1.5rem;
}

.burger-bars {
  width: 2rem;
  cursor: pointer;
  margin-right: 0;
}
.main-nav .navigation-menu.active .burger-bars {
  content: url("../assets//images/close-btn.svg");
}

.header-info span {
  color: var(--pizza-red);
  font-family: var(--garamond);
  font-size: 2rem;
}

.signature-font {
  font-family: 'moonshine' !important;
  font-size: 3rem !important;
  font-weight: YourFontWeight;
  color: #d72229; /* Set the font color to #d72229 */
  /* Add any other desired styles here */
}
.signature-font-2 {
  font-family: 'moonshine' !important;
  font-size: 3rem !important;
  font-weight: YourFontWeight;
  color: #d72229; /* Set the font color to #d72229 */
  /* Add any other desired styles here */
}

.header-info h1 {
  text-transform: uppercase;
  font-size: 3rem;
  line-height: 3rem;
  color: white;
}

.header-info p {
  font-family: var(--garamond);
  font-size: 1.5rem;
}

.header-btns {
  margin-top: 20px; /* Add some space between text and buttons */
  gap: 1.3rem;  /* Horizontal gap between buttons */
  box-sizing: content-box;
  display: flex;
  justify-content: start;
  align-items: center;
}

.header-btns a {
  display: inline-block;
  min-width: 100px;  /* Set min-width to the width of the largest button */
  text-align: center;  /* Center the text */
}

.header-btns a:first-child {
  color: var(--light-black);
  background-color: var(--yellow);
  border: 1px solid transparent;
  margin-right: 20px;  /* Add some space between the buttons */
}

.header-btns a:last-child {
  border-width: 1px;
  border-color: var(--transparent-button);
  background-color: var(--transparent-button);
  color: var(--light-black);
}

.centered-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column; /* This ensures the items stack vertically */
}


.contact-us-landing {
  position: relative;
  overflow-x: hidden;
  padding: 4rem;
  margin-left: 100px;
}

.company-details {
  gap: 1.5rem;
  text-align: left;
  justify-content: space-between;
  font-family: var(--garamond);
  font-weight: 400;
  display: flex;
  flex-direction: column;
  max-width: 700px;
}

.company-details > * {
  text-align: center;
}

.company-details h1 {
  font-family: var(--garamond);
  font-size: 3rem !important;
  font-weight: 400;
  color: #000000;
}

.company-details p {
  color: black;
  font-family: var(--garamond);
  font-weight: 400;
  font-size: 1.2rem;
}

/* Section 2 */
/* CSS to make img1 and img2 bigger and position them differently */
.banner {
  position: relative; /* Add relative positioning to the container */
  display: flex;
  background-color: white;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  font-family: var(--garamond);
}

.banner-img {
  max-width: 20%;
  position: flex; /* Add absolute positioning to all images */
}

/* Adjust the size and position of img1 */
.banner-img.img1 {
  width: 40%; /* Set the width of img1 to make it bigger */
  top: 10px; /* Adjust the top position as needed */
  left: 10px; /* Adjust the left position as needed */
}

/* Adjust the size and position of img2 */
.banner-img.img2 {
  width: 40%; /* Set the width of img2 to make it bigger */
  bottom: 10px; /* Adjust the bottom position as needed */
  right: 10px; /* Adjust the right position as needed */
}

/* Adjust the size and position of the other banner images */
.banner-img:not(.img1):not(.img2) {
  max-width: 20%;
}



.sdg-images {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 60%;
}


/* If needed, you can add margins to the individual banner images */
.sdg-images .banner-img {
  margin: 0 20px; /* Add margin to space the individual SDG images */
}




.text-container {
  flex: 1;
  text-align: center;
  margin: 0 20px;
  display: flex;
  flex-direction: column; /* Stack child elements vertically */
  align-items: center; /* Center align child elements horizontally */
}

.atext-container {
  flex: 1;
  text-align: center;
  margin: 30px;
  display: flex;
  flex-direction: column; /* Stack child elements vertically */
  align-items: center; /* Center align child elements horizontally */
}



.signature-font {
  font-size: 18px;
  font-weight: bold;
}

h2 {
  font-size: 24px;
  margin-top: 10px;
}

/* Responsive Styles for Mobile */
@media only screen and (max-width: 768px) {
  .banner {
    flex-direction: column; /* Stack elements vertically on mobile */
    text-align: center; /* Center align text on mobile */
    padding: 10px;
  }

  .text-container {
    margin: 20px 0; /* Adjust margin for spacing on mobile */
  }

  .banner-img:nth-child(1),
  .banner-img:nth-child(2) {
    max-width: 50%; /* Make both images take up half of the width each on mobile */
    order: 1; /* Keep the order to place the images side by side */
  }

  .banner-img:nth-child(3),
  .banner-img:nth-child(4) {
    max-width: 50%; /* Make both SDG images take up half of the width each on mobile */
    order: 2; /* Keep the order to place the SDG images side by side under images */
  }

  .sdg-images {
    max-width: 40%; /* Expand SDG images to full width on mobile */
    display: flex;
    
    justify-content: center;
  }

  .sdg-images .banner-img {
    max-width: 40%; /* Adjust the width of individual SDG images on mobile */
    margin: 5px; /* Add spacing between SDG images on mobile */
  }

  .banner-img {
    max-width: 100%; /* Expand all images to full width on mobile */
  }
}


.welcome-section {
  overflow: hidden;
}
.section-2-info {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding: 3rem;
}
.section-2-info h2 {
  
  font-size: 2.5rem;
  font-weight: 400;
}

.section-2-info h2 {
  font-family: var(--garamond);
  font-size: 3rem;
  color: var(--black);
}

.section-2-info p {
  font-family: var(--garamond);
  color: black;
  font-size: 1.5rem;
  line-height: 2rem;
  width: 100%;
  max-width: 730px;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .section-2-info {
    margin-right: 1000px !important;
  }
}



/* Section 3 */

/* Section 3 */

.section-3-container {
  font-style: var(--garamond) !important;
  text-align: center;
  background-color: #E5D2C4;
  color: var(--white);
  background-repeat: repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 3rem 1rem;
  max-width: 1440px;
  margin: 0 auto;
}
.section-3-container h2 {
  font-style: var(--garamond) !important;
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 400;
  color: #000000;
}
.section-3-container p {
  font-style: var(--garamond) !important;
  color: var(--white);
  max-width: 1200px;
}

.services-grid {
  gap: 8rem;
  margin-top: 8rem;
  margin: 1rem auto 1rem auto;
}

.services-grid .service-list {
  justify-content: center;
  position: relative;
  z-index: 1;
}
.service-list section {
  background-color: #E5D2C4;
  padding: 1rem;
  position: absolute;
  top: 70%;
  z-index: 2;
  border-radius: 5px;
}

.services-grid img {
  cursor: pointer;
  object-fit: cover;

  max-width: 10rem;
  min-width: 30rem;
  width: 100%;
  height: 43rem;
  border: 1px solid transparent;
  margin: 0 auto;
  z-index: 2;
  
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transform: translate3d(42px, -62px);
  transform-style: preserve-3d;
}

.services-grid h3 {
  font-size: 2rem;
  font-weight: 400;
}
.services-grid p {
  font-size: 1.2rem;
  
}

/* Media Query for Small Screens */
@media (max-width: 1049px) {
  /* Adjust the positioning of the second container for smaller screens */
  .services-grid .service-list section {
    top: 100%;
  }
}



/* Section 4 */
.section-4 {
  gap: 2rem;
  padding-bottom: 3rem;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
.section-4 .active-button-style {
  margin: 0 auto;
}
.section-4-info {
  padding: 2rem;
  font-style: var(--garamond);
  display: flex; /* Add flexbox to center content vertically and horizontally */
  flex-direction: column; /* Stack child elements vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  text-align: center; /* Center-align text within the container */
}

.section-4-info h2 {
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 400;
  color: var(--black); /* Set the color for the heading */
}

.section-4-info p {
  color: var(--white);
  max-width: 1200px;
}

.section-description {
  color: black !important ;
  font-family: var(--garamond);
  font-size: 1.4rem;
  text-align: center;
  max-width: 1200px;
  text-align: center;
  margin: 0 auto;
}

.meals-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  overflow-y: hidden;
  width: 90%;
  margin: 0 auto;
}

.meal-item {
  flex-direction: column;
  gap: 2rem;
  align-items: center;

  padding: 1rem;
  background-color: var(--light-black);
}
.meal-item h3 {
  font-weight: 400;
}
.meal-item img {
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.meal-item-details {
  gap: 0.5rem;
}
.meal-item-details p {
  color: var(--light-gray);
}

.meal-item-order {
  gap: 1rem;

  align-items: center;
}

.meal-item-order p {
  color: var(--yellow);
}

.meal-item-order a {
  border: solid 1px var(--white);
  padding: 0.5rem 1rem;
  letter-spacing: 1.5px;
  letter-spacing: 1.5px;
}


/* Section 5 */
.section-5 {
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
}
.section-5 h2 {
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 400;
}

.section-5 p {
  color: var(--light-gray);
  max-width: 1200px;
}
.pricing-grid {
  gap: 2.5rem;
  max-width: 1440px;
  margin: 0 auto;
}

.pricing-grid-item {
  gap: 1rem;
}
.pricing-details {
  width: 100%;
}
.pricing-details p {
  color: var(--light-gray);
  text-align: left;
}
.name-and-price {
  justify-content: space-between;
  align-items: center;
  letter-spacing: 1px;
  font-size: 1rem;
  position: relative;
  background-color: var(--light-black);
}
.name-and-price::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  border-bottom: 1px solid var(--yellow);
  height: 1px;
}
.name-and-price h3 {
  position: relative;
  color: var(--whitish-gray);
  font-weight: 400;
}

.name-and-price p {
  color: var(--yellow);
}
.pricing-img {
  object-fit: cover;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
}

/* Section 6 */

.gallery img {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  height: 15rem;
}



/* Section 7 */
.section-7 {
  position: relative;
  background-image: url("../assets/images/section-7-bg.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  gap: 1rem;
  padding: 2rem;
}
.section-7::after {
  content: "";
  position: absolute;
  background-color: var(--modal-glass-bg);
  backdrop-filter: blur(5px);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.highlights {
  z-index: 1;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  gap: 3rem;
  flex-wrap: wrap;
}

.highligh-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  text-align: center;
}
.highlights img {
  margin: 0 auto;
  border: 2px solid var(--yellow);
  padding: 0.7rem;
  cursor: pointer;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 3rem;
  height: auto;
}

.highlights h3 {
  color: var(--yellow);
  font-family: var(--poppins-font);
  font-size: 2rem;
}
.highlights p {
  font-weight: 600;
  font-size: 1.5rem;
}

/* Section 8 */
.section-8 {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 2rem;
  overflow: hidden;
}
.menu-slider-hero { 
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: auto;
  overflow: hidden;
}
/* Hero Video */
.hero-section {
  height: 38rem;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hero-video {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  object-fit: cover;
}

.landingpg-img {
  width: 450px;
  position: relative;
  height: auto;
  flex-shrink: 0;
  max-width: 100%;
  margin-top: 600px;
  margin-left: 90px;
}

/* New CSS - Media Query */
@media screen and (max-width: 768px) {
  .landingpg-img {
    display: none; /* This will hide the image on screens smaller than 768px */
  }
}

.hero-section::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.749);
  backdrop-filter: blur(3px); */
  top: 0;
  z-index: 3;
}
.header-info {
  position: absolute;
  z-index: 4;
  justify-content: center;
  gap: 1.5rem;
  align-items: center;
  overflow-x: hidden;
}

.dish-slider .dish-categories {
  margin: 0 auto;
  gap: 1rem;
}
.dish-categories ul {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.dish-categories button {
  border: 1px solid var(--yellow);
  padding: 0.5rem 1rem;
  color: var(--yellow);
  font-family: var(--josefin-font);
  font-size: 1.5rem;
  list-style: none;
  cursor: pointer;
  background-color: transparent;
  text-transform: capitalize;
  transition: all ease-in-out 0.2s;
}

.dish-categories button:hover {
  background-color: var(--yellow);
  color: var(--white);
  transition: all ease-in-out 0.2s;
}

/* Dish Details */
.dish-slider {
  gap: 3rem;
}

.menu-slider-products {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  width: 90%;
}

/* SLIDER PRODUCT */
.slider-product {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.menu-slider-img-section {
  width: 70%;
  height: 10rem;
  min-height: 30%;
  margin: 0 auto;
}
.slider-product img {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: cover;
}

.slider-product h3 {
  font-weight: 400;
}
.slider-product-description {
  width: 15rem;
  max-width: 100%;
  min-width: 70%;
  margin: 0 auto;
}
.dish-details-desc {
  color: var(--whitish-gray);
}

.dish-details-pricing {
  color: var(--yellow);
}

.slider-product a {
  border: 1px solid var(--whitish-gray);
  padding: 0.5rem;
  width: 40%;
  margin: 0 auto;
}

/* Section 9 */
.section-9 .active-button-style {
  margin: 0 auto;
}
.section-9 {
  max-width: 1440px;
  margin: 0 auto;
}

.section-9 h2 {
  color: var(--black) !important;
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 400;
  text-align: center; /* This will center the text */
  margin-left: 0; /* Reset the left margin */
}

/* Blog Posts */
.blog-posts-landing {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 3rem 1.5rem;
}
.blog-posts-landing .post {
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 1rem;
}
.blog-posts-landing .post h3 {
  transition: all ease-in-out 0.3s;
}

.blog-posts-landing h3 {
  font-weight: 500;
}
.blog-posts-landing img {
  max-width: 100%;
  max-height: 60%;
  object-fit: cover;
}

.blog-posts-landing .intro {
  gap: 1rem;
}

.blog-posts-landing .date,
.blog-posts-landing .intro {
  color: var(--black);
}

/* Section 10 */
.section-10 {
  display: flex;
  flex-direction: column;
  font-family: var(--garamond);
  padding-top: 4rem;
  gap: 1rem;
  padding-bottom: 3rem;
  max-width: 1440px;
  width: 90%;
  margin: 0 auto;
  overflow: hidden;
}

.section-10 h3 {
  font-family: var(--garamond);
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 400;
  text-align: left;

}

.contact-emails {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
  font-family: var(--garamond);
  /* padding: 1rem; */
}
.contact-emails h3 {
  font-size: 2rem;
}
.contact-emails h3,
.contact-emails h4 {
  
  color: var(--black);
  font-weight: 500;
}


.contact-emails h3{
  font-size: 2.5rem;
}

.contact-emails h4{
  font-size: 1.5rem;
}
.contact-emails p {
  color: var(--black);
}
.contact-emails ul {
  color: var(--black);
  display: flex;
  flex-direction: column;
}

#map {
  height: 30rem;
  max-width: 90%;
  margin: 0 auto;
}
/* Style for the map title */
.map-title {
  position: absolute;
  left: 50%; /* center the title on the x-axis */
  transform: translateX(-50%); /* shift the title to the left by half of its width */
  font-size: 18px;
  font-weight: bold;
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.map-legend {
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: white;
  padding: 10px;
  border-radius: 3px;
  z-index: 1000; /* Ensure the legend is above all other map elements */
}

.map-legend h4 {
  margin: 0 0 5px;
  font-weight: bold;
}

.map-legend div {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.map-legend img {
  width: 25px;
  height: 41px;
  margin-right: 5px;
}

/* Style for the tooltip */
.leaflet-tooltip {
  background-color: white;
  border: 1px solid black;
  border-radius: 3px;
  color: black;
  font-size: 14px;
  white-space: nowrap;
}


.section-10 .active-button-style {
  margin-top: 2rem;
}

.newsletter-success {
  color: var(--green);
  font-size: 1.5rem;
  line-height: 160px;
}

.email-subscribtion {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  min-width: 375px;
  max-width: 900px;
  color: var(--black) !important;
  margin: 7rem auto;
  overflow: hidden;
}





/* Set the placeholder color to white */
.input-field::placeholder {
  color: var(--placeholder-color);
}


@-webkit-keyframes gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}
.webflow-style-input {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  border-radius: 5px;
  opacity: 0.8;
  width: 100%;
  padding: 0.5rem 1.2rem;
  background-color: rgba(20, 20, 20, 0.672);
}
.webflow-style-input:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  height: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-position: 0% 0%;
  background: linear-gradient(to right, #f56456, #dfe657, #ee5e41, #ffff10, #b294ff, #57e6e6);
  background-size: 500% auto;
  -webkit-animation: gradient 3s linear infinite;
  animation: gradient 3s linear infinite;
}

.webflow-style-input input,
.webflow-style-input textarea {
  border-style: none;
  background: transparent;
  outline: none;
  color: var(--whitish-gray);
  flex-grow: 1;
  line-height: 2.4rem;
  vertical-align: middle;
  font-size: 1rem;
  font-family: var(--poppins-font);
}

.input-section {
  min-width: calc(40% + 5rem);
  max-width: 40%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.input-section form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  width: 100%;
}

.email-label {
  font-family: var(--poppins-font);
  color: var(--whitish-gray);
  font-size: 1.2rem;
  text-align: center;
  max-width: 650px;
}

.error-msg {
  font-family: var(--poppins-font);
}


/* Transitions */
.navigation-menu a,
.logo,
.header-btns a:last-child,
.header-btns a:first-child,
.meal-item-order a,
.more-pizza,
.dish-categories h3,
.dish-details a,
.form-btn,
.txt-white,
.login-and-cart .cart-btn,
.active-button-style,
.passive-button-style {
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.active-button-style:hover {
  background-color: transparent;
  color: var(--white);
  border-color: var(--white);
}
.passive-button-style:hover {
  background-color: var(--pizza-red);
  border-color: var(--pizza-red);
}

/* Hover */
@media (hover: hover) {
  .navigation-menu a:hover,
  .logo:hover,
  .header-btns a:last-child:hover,
  .header-btns a:first-child:hover,
  .meal-item-order a:hover,
  .more-pizza:hover,
  .dish-categories h3:hover,
  .dish-details a:hover,
  .form-btn:hover,
  .login-and-cart .cart-btn:hover,
  .active-button-style:hover,
  .passive-button-style:hover,
  .blog-posts-landing .post h3:hover {
    -moz-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
    -ms-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
  }

  .navigation-menu a:hover {
    color: var(--pizza-red);
  }
  .logo:hover {
    transform: rotate(10deg);
  }
  .blog-posts-landing .post h3:hover {
    color: var(--pizza-red);
  }

  .profile-icon {
    position: relative;
    cursor: pointer;
  }
  
  .logout-text {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .profile-icon:hover .logout-text {
    display: block;
  }
  
  .header-btns a:last-child:hover {
    color: var(--light-black);
    background-color: var(--yellow);
    border: 1px solid transparent;
  }
  .header-btns a:first-child:hover {
    border: 1px solid var(--yellow);
    color: var(--light-black);
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.637);
    background-color: rgba(255, 255, 255, 0.637);
  }

  .meal-item-order a:hover {
    background-color: var(--yellow);
    border: 1px solid transparent;
    color: var(--light-black);
  }
  .more-pizza:hover {
    background-color: var(--yellow);
    color: var(--light-black);
    border: 1px solid transparent;
  }
  .dish-categories h3:hover,
  .dish-details a:hover {
    background-color: var(--yellow);
    color: var(--light-black);
  }
  .form-btn:hover {
    border: 1px solid var(--yellow);
    color: var(--yellow);
    background-color: transparent;
  }
  .login-and-cart a:hover {
    background-color: var(--pizza-red);
    border-color: var(--pizza-red);
    color: var(--white);
    opacity: 0.5;
  }
}


.navigation-menu.active {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  left: 0;
  z-index: 99;
  background-color: var(--light-black);
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  height: 100vh;
}

/* ADD TO CART BUTTON */
.passive-button-style.active-add-to-cart {
  background-color: var(--green);
  border-color: var(--green);
  cursor: pointer;
}
.passive-button-style.active-add-to-cart:hover {
  opacity: 0.7;
  transition: all ease-in-out 0.2s;
}

.passive-button-style.inactive-add-to-cart {
  color: rgba(255, 255, 255, 0.22);
  border-color: rgba(255, 255, 255, 0.22);
  background-color: rgba(255, 255, 255, 0.22);
  cursor: not-allowed;
}

/* Success Msg */
.success-msg {
  display: none;
}
.success-msg.visible {
  display: inline-block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(5px);
  padding: 0.5rem;
  border-radius: 10px;
}
.success-msg.visible p {
  font-family: var(--poppins-font);
  font-size: 1rem;
  color: var(--white);
  font-weight: 500;
}

.socials {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.socials span {
  display: none;
}

.socials img {
  max-width: 2rem;
  width: 100%;
  height: 100%;
}

.pizza-one {
  position: absolute;
  top: -12%;
  width: 1rem;
  opacity: 0.8;
  overflow: hidden;
}
.pizza-two {
  position: absolute;
  left: 5%;  /* adjust this */
  top: -12%;
  width: 1rem;
  opacity: 0.8;
  overflow-y: hidden;
}


.company-details-image {
  margin-left: 10px;
  position: absolute;
  width: 25rem;
  overflow-y: hidden;
  left: 200px;  /* move to the right */
}

.company-details-image-two {
  position: absolute;
  width: 25rem;
  overflow-y: hidden;
  left: 320px;  /* move to the right */
  bottom: 25px;
}

.company-details-image-three {
  position: absolute;
  width: 25rem;
  overflow-y: hidden;
  left: 280px;  /* move to the right */
}
.custom-list {
  width: 100%;
  border-collapse: collapse;
  font-family: var(--garamond);
  font-size: 1.2rem;
}

.custom-list td {
  padding: 10px;
  border: none; /* Remove table borders */
  vertical-align: top;
  color: #000000; /* Set text color to black */
  text-align: left; /* Align text to the left */
}

@media only screen and (max-width: 768px) {
  .custom-list td {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
    border: none;
  }
}


.pizza-motion {
  display: none;
  margin: 0;
}

.terms {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
}
.terms h2 {
  font-size: 3rem;
  color: white;
  text-align: center;
  font-family: var(--poppins-font);
}
.terms p,
.terms h3 {
  color: var(--light-gray);
}

