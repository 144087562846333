.prog {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-image: url("../../assets/images/landing-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  margin-top: 2rem;
  margin-bottom: 3rem;
  justify-content: center;
  font-style: var(--garamond) !important;
}
.prog-section h2,
.prog-intro,
.prog-grid {
  font-family: var(--garamond);
}


.prog-grid h3 {
  font-size: 1.5rem !important;
  color: black;
  justify-content: left;
}

.prog-section h2 {
  font-size: 3rem;
  color: black !important;
  justify-content: left;

}
.prog-intro {
  color: black;
  font-size: 1.5rem;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 3rem;
}

.prog-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2px !important; /* Adjust this value to reduce the space between rows */
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
}



.prog-grid-item {
  
  width: 80%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: left;
  margin-left: 30px;
}

.prog-grid-item img {
  max-width: 100%;
  height: 22rem;
  object-fit: cover;
}

.prog-grid-item h3 {
  transition: all ease-in-out 0.3s;
}
.prog-grid-item .credentials {
  color: var(--black);
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.prog-grid-item .bintro {
  color: var(--black);
  font-size: 1.2rem;
}

.prog-grid h3 {
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 400;
  color: var(--black) !important;
}

/* Define the black-text class */
.black-text {
  color: white !important; /* Set the text color to black */
  font-weight: 600 !important;
  font-size: 1.5rem;
}
.prog .prog-pagination {
  position: initial;
  display: flex;
  flex-direction: row;
  margin-top: 4rem;
  margin-bottom: 3rem;
}


.prog-grid-item-wrapper {
  display: flex;
  align-items: start; /* To align items to the top */
}

.prog-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Gap between the buttons */
  margin-right: 20px; /* Spacing between the buttons and the prog content */
}


/***************  RESPONSIVE ***************/

@media (hover: hover) {
  .prog-grid-item h3:hover {
    color: var(--pizza-red);
    transition: all ease-in-out 0.3s;
  }
}

@media screen and (min-width: 700px) {
  .prog-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1110px) {
  .prog-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
